<template functional>
  <article>
    <h2 class="text-center main-heading" v-html="props.heading"></h2>
    <p
      v-html="props.paraghText"
      class="main-description text-center  mx-auto managers-council__text"
    ></p>
  </article>
</template>

<script>
export default {
  name: "Articals",
  props: {
    heading: {
      type: String,
      required: true
    },
    paraghText: {
      type: String,
      required: true
    }
  }
};
</script>
